import { defineComponent, nextTick, onBeforeUnmount, ref } from 'vue';
import ArrowDown from '../../assets/svg/arrow-down.svg';
import VideoComponent from '../atoms/VideoComponent.vue';
import playImage from '../../assets/media/Play.png';
export default defineComponent({
    components: { VideoComponent, ArrowDown },
    name: 'ImageText',
    props: { videoUrl: String },
    setup() {
        const style = ref({});
        const showVideo = ref(false);
        const content = ref(null);
        const video = ref(null);
        const scrollHandle = () => {
            if (content.value) {
                const aboutUs = document.querySelector('.about-us')
                    ?.offsetTop ?? 0;
                const rect = content.value.getBoundingClientRect().top;
                const translate = Math.min(Math.max(-rect, 0), 1500);
                style.value = {
                    transform: `translateX(${translate}px)`,
                    opacity: 1 - translate / 650,
                };
                if (aboutUs && translate === 1200) {
                    style.value = { ...style.value, opacity: 0 };
                }
            }
        };
        const playVideo = () => {
            showVideo.value = true;
            nextTick(() => video.value?.play());
        };
        window.addEventListener('scroll', scrollHandle);
        onBeforeUnmount(() => {
            window.removeEventListener('scroll', scrollHandle);
        });
        return {
            style,
            content,
            ArrowDown,
            showVideo,
            playImage,
            playVideo,
            video,
        };
    },
});
